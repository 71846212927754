<template>
  <v-container>
    <v-progress-circular :size="100" indeterminate />
  </v-container>
</template>
<script>
import { auth } from '../../sharedPlugin';

export default {
  computed: {
    ...auth.mapComputed(['loggedIn', 'user'])
  },
  methods: {
    ...auth.mapMethods(['checkToken'])
  },
  created() {
    this.checkToken()
      .then(() => {
        if (!this.loggedIn) throw new Error('not logged in');

        if (this.user.role === 'ehrUser')
          return this.$router.push({
            path:
              this.$route.query.redirect === '/'
                ? '/messages'
                : this.$route.query.redirect
          });

        this.$router.push({ path: this.$route.query.redirect ?? '/' });
      })
      .catch(() =>
        this.$router.push({ name: 'Login', query: this.$route.query })
      );
  }
};
</script>
